import React,{useState,useRef} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, CircularProgress} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import 'bootstrap/dist/css/bootstrap.min.css';
import { LocalizationProvider } from "@mui/x-date-pickers";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import SnackBar from "./SnackBar";





const UploadFile=(props)=>{
    const alertRef=useRef();
    const [loading,setLoading]=useState(false);

 
    const [formData,setFormData]=useState({
    payerName:'',
    contractName:'',
    agreementId:'',
    effectiveDate:null,
    files:[],
});

const handleChange=(e)=>{
    const {name,value}=e.target;
    setFormData({
        ...formData,
        [name]:value,
    });
};

const handleDateChange=(newDate)=>{
   setFormData((prev)=>({
    ...prev,
    effectiveDate:newDate,
   }));
};

const handleFileChange=(e)=>{
    const file=e.target.files[0];
    setFormData((prev)=>({
        ...prev,
        files:file,
       }));
};

const resetForm=()=>{
 setFormData({
    payerName:'',
    contractName:'',
    agreementId:'',
    effectiveDate:null,
    files:[],
 })
}

const handleSubmit=async(e)=>{
   e.preventDefault();
   if(!formData.payerName||!formData.contractName||!formData.agreementId||!formData.effectiveDate||formData.files.length===0){
    alertRef.current.showSnackbar("All fields are required","error");
    // resetForm();
    return;

   }
   const data=new FormData();
   data.append('request_id',props.uniqueId)
   data.append('payer_name',formData.payerName);
   data.append('contract_name',formData.contractName);
   data.append('agreement_id',formData.agreementId);
   data.append('effective_date',formData.effectiveDate?formData.effectiveDate.format('YYYY-MM-DD').toString():'');

//    formData.files.forEach((file,index)=>{
//     data.append('contract_data',file);
//    });
    if(formData.files){
        data.append('contract_data',formData.files)
    }
   for(let [key,value]of data.entries()){
    console.log(`${key}:`,value);
    // console.log('data',data.entries());
   }

   try{
    const header = {
        headers: { "Content-Type": "multipart/form-data" },
        timeout:800000,
    }
    setLoading(true);
    const response = await axios.post(
        process.env.REACT_APP_API_URI+"upload_contracts",data,header
      );
      console.log("response at first",response);

      if(response.data==='Document uploaded successfully'){
     alertRef.current.showSnackbar("Data Uploaded Successfully","success");
      
      setLoading(false);
      resetForm();
       props.handleClose();
      }
      if(response.data!=='Document uploaded successfully'){
        alertRef.current.showSnackbar(""+response.data+"","error");
         
         setLoading(false);
         resetForm();
          props.handleClose();
         }
        
    
    }
   catch(error){
    alertRef.current.showSnackbar();
    setLoading(false);
    resetForm();
    props.handleClose();

   }


}

const handleCloseDialog=()=>{
    resetForm();
    props.handleClose();
}



return(
    <div >
        <Dialog open={props.open} onClose={handleCloseDialog} maxWidth="md" fullWidth >
        <DialogTitle sx={{ p:2 ,backgroundColor:'#002377',color:'white',paddingLeft:'2.5%'}} id="customized-dialog-title" >
         <div class="browseandUploadFiles">Browse & Upload Files</div>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: "white",
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>


            <form  className="bootstrap-form" >
                <div className="form-row ">
                    <div className="form-group col-md-4" style={{display:'inline'}}>
                        <label htmlFor="inputPayerName " className="bold"><sup className="colorRedFontBold" >*</sup>Payer Name</label>
                        <input
                        type="text"
                        className="form-control uploadFileCss1"
                        id="inputPayerName"
                        name="payerName"
                        // value={formData.payerName}
                        onChange={handleChange}
                        />
                    </div>
                    <br></br>
                    <div className="form-group col-md-4" style={{display:'inline'}}>
                        <label htmlFor="inputContractName"className="bold"><sup className="colorRedFontBold" >*</sup>Contract Name</label>
                        <input
                        type="text"
                        className="form-control uploadFileCss1"
                        id="inputContractName"
                        name="contractName"
                        // value={formData.contractName}
                        onChange={handleChange}
                        />

                    </div>
                    <br></br>
                    <div className="form-group col-md-4" style={{display:'inline'}}>
                        <label htmlFor="inputAgreementId"className="bold"><sup className="colorRedFontBold" >*</sup>Agreement ID</label>
                        <input
                        type="text"
                        className="form-control uploadFileCss1"
                        id="inputAgreementId"
                        name="agreementId"
                        // value={formData.agreementId}
                        onChange={handleChange}
                        />
                    </div>
                    <br></br>


                    <div className="form-group col-md-4">
                        <label htmlFor="inputEffectiveDate"className="bold"><sup className="colorRedFontBold" >*</sup>Effective Date</label>
                       <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                        // value={formData.effectiveDate}
                        onChange={handleDateChange}
                        renderInput={(params)=><TextField   {...params} fullWidth/>}
                        inputFormat="YYYY-MM-DD"
                        />
                       </LocalizationProvider>
                    </div>
                    <br></br>


                <div className="form-row align-items-center">
                    <div className="form-group col-md-6">
                        <label className="bold"><sup className="colorRedFontBold" >*</sup>Upload PDF</label>
                        <input
                        type="file"
                        name="files"
                        className="form-control uploadFileCss1"
                        accept="application/pdf"
                        onChange={handleFileChange}
                        />

                    </div>
                </div>
                <br></br>
                <label className="bold">This (<label className="colorRedFontBold" >*</label>) mark indicates required fields</label>

                </div>



            </form>
        </DialogContent>
        <DialogActions>
          <Button  onClick={handleSubmit} style={{ backgroundColor: '#002377',color:'white', marginTop: '0px', width: '20%', borderRadius: '0px'}} >
            {loading?<CircularProgress size={24} style={{color:'#fff'}} />:'Submit'}
          </Button>
        </DialogActions>

        </Dialog>
        <SnackBar ref={alertRef}/>     


    </div>
)


};


export default UploadFile;
