import React, { useState } from "react";
import { Link } from "@material-ui/core";

const ExpandText = ({ text, maxLength}) => {
  const [isExpand, setIsexpand] = useState(false);
  const handleExpand = () => {
    setIsexpand(!isExpand);
  };

  return (
    <>
    {text !== null && text!==undefined && text.length > maxLength ? (<div>
       {isExpand ?<div dangerouslySetInnerHTML={{ __html:text  }}></div>:<div dangerouslySetInnerHTML={{ __html:text.slice(0, maxLength)  }} style={{display:'inline'}}></div> } 
      {text.length > maxLength && 
        <Link  onClick={handleExpand} style={{fontWeight:'bold',display:'inline'}}>
         <div style={{display:'inline'}}>
        {isExpand ? " Show less" : " Show more"}
        </div>
          
      </Link>}
    </div>):(<div dangerouslySetInnerHTML={{ __html:text  }}></div>)}
    </>
  );
};

export default ExpandText;