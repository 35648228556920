import React from 'react'
import { useState, useEffect } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextInput,
} from 'material-react-table';
import useLoader from '../Hook/useLoader';




const Client = (props) => {
  const [showIsLoading, setShowIsLoading] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [data, setData] = useState([]);


 

  const [columns, setColumns] = useState(
    () => [
      {
        accessorKey: 'client',
        header: 'Client',
        size: 150,
      },
    ],
    [],
  );

  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    state: {
      isLoading: showIsLoading,
    },
    enableColumnActions: false,
    enableColumnFilters: false,
    enablePagination: false,
    enableFacetedValues: false,
    enableDensityToggle: false,
    enableHiding: false,
    enableFullScreenToggle: false,
    enableStickyHeader:true,
    initialState: { showGlobalFilter: true },
    positionGlobalFilter: 'left',
    muiTableBodyCellProps: ({ column }) => ({
      sx: {
        fontSize: '12px',
      },
    }),
    muiTableHeadCellProps: {
      sx: {
        fontSize: {
          xs: '10px',
          sm: '11px',
          md: '11px',
          lg: '12px',
          xl: '13px',
        },
        padding: '5px'
      },
    },
  });

  const iframeStyles = {
    width: '100%',
    height: '70vh', // Adjust the height as per your requirement  
  };

  useEffect(() => {
    try{
      handleSimilarSearch();

    }
    catch(error){
    //   AppInsight.trackException({error});
    //   AppInsight.trackEvent({name:'useEffect-error-in-Client=>frontend',properties:{errorMessage:error.message}});
    //   navigate("/Error");
    //   setShowSnackBar(true);

    }
  }, [])


  const handleSimilarSearch = async () => {
    try {
      console.log("similar search called");
      let data_temp = [];
      let obj = {};
      props.data.map((item, index) => {

        obj = { 'client': item }
        data_temp.push(obj);
      })
      setData(data_temp);
      setShowTable(true);
      setShowIsLoading(false);
    }
    catch (error) {
    //   console.log("error is", error);
    //   AppInsight.trackException({error});
    //   AppInsight.trackEvent({name:'error->handleSimilarSearch-Client=>frontend',properties:{errorMessage:error.message}});
    //   navigate("/AuthError");
    //   setShowSnackBar(true);
   
    }
  }

 

  return (

  
    <div className="modal1" id="modalpopup" >
      <div className="modal-content" style={{ height: '96%', width: '40%', top: '2%', borderRadius: '0px', padding: '20px 0px 20px 20px', margin: 'auto' }}>

        <div class="container">
          <div className='row'>
            <div className="col-md-8" style={{ fontSize: '20px' }}>
              {props.title}
            </div>
            <div className="col-md-2" style={{ display: 'flex', justifyContent: 'end' }}>
            </div>

            <div className="col-md-2" style={{ display: 'flex', justifyContent: 'end' }}>
              {/* <i class="bi bi-x" onClick={props.close} style={{ fontSize: '30px', cursor: 'pointer' }}></i> */}
              <button type="button" style={{border:'0px',backgroundColor:'white'}} onClick={props.close} class="close" aria-label="Close"><span aria-hidden="true" style={{fontSize:'30px'}}>&times;</span></button>
            </div>
          </div>
        </div>
        <div class="container" style={{ minHeight: '500px', marginTop: '2%' }}>
          {showTable &&
            <MaterialReactTable table={table} />
          }
        </div>
      </div>
    </div>
  )

}
export default Client;