import React,{useRef,useState,useEffect} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import { IoSearchSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import UploadFile from '../Components/UploadFile';
import {v4 as uuidv4} from 'uuid';
import { TokenGetter, GetUserInfo,getCookie,setCookie } from "../Endpoints/LoginActions";
import UploadContractImage from '../Img/UploadContractsImg.png';
import AccessResultsOffshore from '../Img/AcessOffshoreResults.png';
import bubbleImg from '../Img/bubbleImg.png';

 
const SearchPage=()=>{
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const searchText = useRef(null);
  const [uniqueId, setUniqueId]=useState(null);
  const authCookieToken=getCookie('authToken');
  const handleSearch = async () => {
    if (searchText.current.value !== "") {
      try {
        window.open(`/search/${searchText.current.value}`);
      }
      catch (error) {
      }
    }
    else { }
  }
  const handleKeyDown = (e) => {
    try{
    if (e.key === 'Enter') {
      handleSearch();
    }

  }
  catch(error)
    {
      // AppInsight.trackException({error});
      // AppInsight.trackEvent({name:'error-handleKeyDown-Home=>frontend',properties:{errorMessage:error.message}});
      // setShowSnackbar(true);
    }
  }
  const handleClickOpen=()=>{
    setUniqueId(uuidv4());
    setOpen(true);
  }
  const handleClose=()=>{
    setOpen(false);
  } 
  return (
    <div className="Search-Home">
          <div className="HeadingApp1">
            <br></br>
           <div className='SearchEngine '>
              <h4>Search Engine</h4>
            </div>
            <div className='SearchBelowContent '>
            <h6>A feature to perform generic search on top of LHC Contracts</h6>
            </div>
            <input type="text" ref={searchText} onKeyDown={(e) => handleKeyDown(e)}  placeholder='search'  className='SearchInput' />
            <i className='SearchIcon'  ><IoSearchSharp onClick={() => handleSearch()}/></i>
          
          </div>
          <img src={bubbleImg} alt={bubbleImg} style={{width:'25%',marginTop:'-11%',marginLeft:'70%'}}/>
          <div className='Text21k'>
                885
                <div className='TextContracts'>
                    Contracts
                </div>
          </div>
          <div className='Text3839'>
                221
                <div className='TextClients'>
                    Clients
                </div>
          </div>
          <br></br>

          <div  className='TextOffshoreRestrictionProcessing'>
            Offshore Restriction
            <div className='OffshoreRestrictionProcessingBelowSentence'>
                    A feature to fetch offshore restriction information from LHC contacts
                </div>
          </div>
          <br></br>
          <div className='innerCircle2 UploadContentDivBlueCircle'>
          <img src={UploadContractImage} alt='UploadContractImage' onClick={handleClickOpen} className='height icon'/>
          </div>
          <div className='UploadContent'  onClick={handleClickOpen}>Upload Contract</div>
          <div className='innerCircle2 AcessResultDivBlueCircle'>
          <img src={AccessResultsOffshore} alt='AccessResultsOffshore' className='height icon'onClick={()=>(navigate('/Archive'))}/>
          </div>
          <div className='AccessResults' onClick={()=>(navigate('/Archive'))}>Access Offshore Results</div>
          {/* <IoMailOutline size={40} color='#002b80' onClick={handleInviteClickOpen} title='send invite' className='invite'/> */}
          
  
<UploadFile open={open} handleClose={handleClose} uniqueId={uniqueId}/>
{/* <Invite open={inviteOpen} handleClose={handleInviteClose}/> */}

   </div>
  )
}

export default SearchPage;


