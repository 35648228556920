import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication,EventType } from "@azure/msal-browser";
import { ssoConfig } from "./config";
import { UserContext} from './Hook/UserContext';
import { Provider } from 'react-redux';
import {store,persistor} from "../src/Store";
import { PersistGate} from 'redux-persist/integration/react';


const msalInstance = new PublicClientApplication(ssoConfig);
await msalInstance.initialize();
msalInstance.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount(event.payload.account);
  }
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
    <BrowserRouter>
    <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
     <App />
     </PersistGate>
     </Provider>
      </BrowserRouter>
    </MsalProvider>
  </React.StrictMode>
);

reportWebVitals();
