import axios from "axios";
import { saveUserInfo } from '../Store/slices/UserInfoSlice';



export const TokenGetter = (code, callback ) => {

    const url = process.env.REACT_APP_OHID_WRAPPER_TOKEN_URL || '';

    const header = {
        headers: { "Content-Type": "application/x-www-form-urlencoded","Access-Control-Allow-Origin": "*" }
    }
    

    axios.post(url, data(code), header)
        .then((response)=>{
            callback(response.data.access_token);
            return response.data.access_token;
        })
        .catch((error)=>{
            return error;
        })
}

const data = (auth_code) => {
    return {
        'code' : auth_code,
        'client_id':process.env.REACT_APP_OHID_TOKEN_CLIENT_ID,
        'client_secret':process.env.REACT_APP_OHID_TOKEN_CLIENT_SECRET,
        'grant_type':process.env.REACT_APP_OHID_TOKEN_GRANT_TYPE,
        'redirect_uri':process.env.REACT_APP_OHID_REDIRECT_URI,
    }
}

export const GetUserInfoCode = async (code,dispatch, callback) => {

    console.log("entered USerInfo Code")
    if(!code) return ;


    const headers = {
        headers: { "Content-Type": "application/json" }
    }



    const url = process.env.REACT_APP_API_URI +"user_info" || '';

    const  obj={
        "request_id":"1234",
        "auth_code":code
    }
    

      axios.post(url,obj,headers)
        .then((response)=>{
            console.log("userInfo response in backend api call is.....,",response)
            //dispatch(saveUserInfo(response.data));
            //setCookie("userInfo",JSON.stringify(response.data),0);
            if(response.hasOwnProperty('data')&&response?.data.hasOwnProperty('Result'))
            {
                if(response?.data?.Result!==null)
                {
                    console.log("userInfo stored in session");
                    sessionStorage.setItem("userInfo",JSON.stringify(response?.data?.Result));
                }
            }
            // const userInfoTemp={
            //     name:"Shetye, Rajesh"
            //   }

              //set the userInfo object returned from api here and also see what api returns data successfully, in case of any error ,set the 
              //corresponding error value in app.js string
            callback("");
            return response;
        })
        .catch((error)=> {
            console.log("reached userInfo code catch")
            return error;

        });



       


        

        
}

export const GetUserInfo = async (accessToken,dispatch, callback) => {


    if(!accessToken) return ;



    const url = process.env.REACT_APP_OHID_WRAPPER_USERINFO_URL || '';

    axios.get(`${url}?access_token=${accessToken}`)
        .then((response)=>{
            console.log("userInfo response is.....,",response)
            dispatch(saveUserInfo(response.data));
            //setCookie("userInfo",JSON.stringify(response.data),0);

            callback(JSON.stringify(response.data));
            return response.data;
        })
        .catch((error)=> {
            return error;
        });
}


export const setCookie = (name, value, minutes) => {
  // set cookies local to use in all app
  // console.log('Set coocies', name, value, minutes);
  let expires = '';
  if (minutes) {
    const date = new Date();
    date.setMinutes(date.getMinutes() + minutes);
   //expires = `; expires=${date.toUTCString()}`;
   //${expires}
  }
  // console.log('************', (document.cookie = `${name}=${value || ''}${expires}; path=/`));
  document.cookie = `${name}=${value || ''}; path=/`;
  // console.log(' after Set coocies', document.cookie);
};
 
export const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
