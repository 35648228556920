import React, { Fragment, useState } from "react";
import {
  FaBars,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import searchViewImg from '../Img/Search Features.png';
import offshoreArchieve from '../Img/Offshore Archive.png';
import templateReview from '../Img/Template Review.png';


const Sidebar = (props) => {
  const [show, setShow] = useState(false);
  return (
    <Fragment>
      <Stack direction="row" spacing={0}>
        <div
          className={`p-2 ${'sidebarPanel'} ${
            !show ? 'sidebarClosed' : ""
          }`}
        > 
          <div className={`sidebar ${"marginTop70"}`}>
            <div className={"displayFlex"}>
              <div
                // onClick={() => setShow(!show)}
                className={`${"colorWhite"} ${"width100"}`}
              >
          <div className={"menuText menuTextMarginMenu"}
          >
                  <Typography onClick={() => setShow(!show)}><b>Menu</b></Typography>
                </div>
                <FaBars title="menu" onClick={() => setShow(!show)}
                  className={`${"marginTop4"} ${"menuIcon"}`}
                />
              </div>
            </div>

            <div className={"displayFlex"}>
              <NavLink
                className={`${"colorWhite"} ${"width100"}`}
                to="/"
              >
                <div className={"menuText"}>
                  <Typography ><b>Search Feature</b></Typography>
                </div>
                <img src={searchViewImg} title="search view" alt="searchImg"
                  className={`${"marginTop4"} ${"menuIcon"}`}
                />
              </NavLink>
            </div>

            <div className={"displayFlex"}>
              <NavLink
              className={({ isActive }) => 
                isActive
                  ? `${"colorOrange"} ${"width100"}`:`${"colorWhite"} ${"width100"}`
              }
                // className={`${"colorWhite"} ${"width100"}`}
                to="/Archive"
              >
                <div className={"menuText menuTextMarginOffshoreArchive"}>
                  <Typography><b>Offshore Results</b></Typography>
                </div>
                <img src={offshoreArchieve} title="offshore results" alt="offshoreArchieveImg" className={"menuIcon"} />
              </NavLink>
            </div>

            {/* <div className={"displayFlex"} >
              <NavLink
                className={({ isActive }) => 
                  isActive
                    ? `${"colorOrange"} ${"width100"}`:`${"colorWhite"} ${"width100"}`
                }
                to="/ClientSummary/:payer_name"
                
              >
                <div className={"menuText menuTextMarginTemplate"}>
                  <Typography><b>Client Summary</b></Typography>
                </div>
                <img src={templateReview} alt='templateReviewImg' className={"menuIcon"} />
              </NavLink>
            </div> */}

           </div>
        </div>
        {/* <div
          className={`p-2 ${"mainPanel"} ${
            show ? "mainClosed" : ""
          }`}
        >
          {props.children}
        </div> */}
      </Stack>
    </Fragment>
  );
};

export default Sidebar;
