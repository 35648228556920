import React from 'react'
import { useState, useEffect } from 'react';
import axios from "axios";
// import AppInsight from '../AppInsight';
import { useNavigate } from 'react-router-dom';
// import SnackBar from "../Component/SnackBar";
import useLoader from '../Hook/useLoader';
import { Typography } from '@mui/material';



const ContractViewImage = (props) => {
  const [loader,showLoader, hideLoader] = useLoader();
  const [showIsLoading, setShowIsLoading] = useState(true);
  const [showTable, setShowTable] = useState(true);
  const [imageUrl, setImageUrl] = useState([]);
  const [showSnackBar,setShowSnackBar]= useState(false)

  const navigate = useNavigate();


  useEffect(() => {
    try{
    handleSimilarSearch();

    }
    catch(error){
      // AppInsight.trackException({error});
      // AppInsight.trackEvent({name:'useEffect-error-in-contractViewImage=>frontend',properties:{errorMessage:error.message}});
      // setShowSnackBar(true);
     
    }
  }, [])

  const handleSimilarSearch = async () => {
    try {

    showLoader();
      const header = {
        headers: {
          'Content-Type': 'application/json'
        },
      };
      const json = JSON.stringify({
        "Agreement_ID": props.agreement_Number,
        "Page_no": props.page_Number
      });
      console.log("Image json request", props);
      const response = await axios.post(process.env.REACT_APP_API_URI + "image", json, header)
      console.log("response image is..............", response);
      if (response.status === 200) {
        hideLoader();
        setShowTable(true);
        setShowIsLoading(false);
        setImageUrl(response.data.const_url);
        
      }

      // AppInsight.trackEvent({name:'data-succes-in-contractViewImage=>frontend'});

    }
    catch (error) {
      console.log("error is", error);
      // AppInsight.trackException({error});
      // AppInsight.trackEvent({name:'data-fetch-error-in-contractViewImage=>frontend',properties:{errorMessage:error.message}});
      // setShowSnackBar(true);

    }
  }

  // const closeSnackBar=()=>{
  //   setShowSnackBar(false);
  // }
  return (

    <div className="modal1" id="modalpopup" 
  //   ref={(ref) => {
  //     if (ref) {
  //         ref.addEventListener('click', closeSnackBar);
  //     }
  // }} 
  >
       {/* <SnackBar open={showSnackBar}/> */}
  
      <div className="modal-content" style={{ height: '96%', width: '70%', minWidth: '30%', top: '2%', margin: 'auto', borderRadius: '0px', padding: '20px 0px 20px 20px' }}>

        <div class="container" style={{ marginTop: '-3%' }}>
          <div className='row'>
            <div className="col-md-6" style={{ fontSize: '20px' }}>
            </div>
            <div className="col-md-4" style={{ display: 'flex', justifyContent: 'end' }}>
            </div>

            <div className="col-md-2" style={{ display: 'flex', justifyContent: 'end' }}>
              <button type="button" style={{ border: '0px', backgroundColor: 'white' }} onClick={props.close} class="close" aria-label="Close"><span aria-hidden="true" style={{ fontSize: '30px' }}>&times;</span></button>
            </div>
          </div>
        </div>
        <div class="container" style={{ minHeight: '500px', marginTop: '0%' }}>
          {loader}
          {showTable &&

            <div style={{ overflowY: 'auto', height: '100%' }}>
              {imageUrl===""?<Typography>No Image Available</Typography>:<img src={imageUrl} width="900px" />}

            </div>
          }
          {/* {showLoader &&
          loader
          } */}

        </div>

        <div class="container" style={{ marginTop: '4%', marginLeft: '-4%' }}>
          <div className='row'>
            <div className="col-md-8">

            </div>
            <div className="col-md-2" style={{ display: 'flex', justifyContent: 'end' }}>
            </div>

            <div className="col-md-2" style={{ display: 'flex', justifyContent: 'end' }}>
              <button className="btn btn-primary" onClick={props.close} style={{ backgroundColor: '#002377', marginTop: '0px', width: '80%', borderRadius: '0px', marginBottom: '10px' }}>Close</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  )

}
export default ContractViewImage;