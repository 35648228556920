import { configureStore } from "@reduxjs/toolkit";
import UserInfoSlice from "./slices/UserInfoSlice";
import localforage from "localforage";
import {combineReducers} from 'redux';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig={
    key:'root',
   storage:localforage,
};
 
const userInfoPersist= persistReducer(persistConfig,UserInfoSlice);

export const store = configureStore({
    reducer: {
        UserInfoSlice:userInfoPersist,
    },
 
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
 
export const persistor=persistStore(store);