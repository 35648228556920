import React from 'react'
import { useState, useEffect,useMemo } from 'react';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { Box, Button } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';
import ExpandText from './ExpandText';
import { Navigate, useNavigate } from 'react-router-dom';


const LandingPageTable=(props)=> {
  
  const [data, setData] = useState(props.data);
  const [showTable, setShowTable] = useState(false);
  const [reviewFlag] = useState(["Accepted","Denied","Pending"]);
  const [validationErrors] = useState({});
  const navigate=useNavigate();

   useEffect(() => {
    try{
    setShowTable(props.tableValue);
    }
    catch(error){}
  }, [props.tableValue])
  useEffect(() => {
    try{
    setData(props.data);
    }
    catch(error){}
  }, [props.data]);  
  const csvConfig = mkConfig({
    fieldSeparator: ',',
    decimalSeparator: '.',
    useKeysAsHeaders: true,
  });
  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(data);
    download(csvConfig)(csv);
  };
  const handleExportRows = (rows) => {
    const rowData = rows.map((row) => row.original);
    const csv = generateCsv(csvConfig)(rowData);
    download(csvConfig)(csv);
  };

  console.log(data,"data in landingpagetable")

  

 

  let columns = useMemo(
    () => [
      {
        accessorKey: 'Agreement_ID', //normal accessorKey
        header: 'Agreement ID',
        minSize: 200,
        maxSize: 200,
        size: 200,
        muiTableHeadCellProps: { sx: { color: "#002677" } ,align:'center'}, //custom props
        enableColumnFilter: true,
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder: 'Agreement ID' },
      },
      {
        accessorKey: 'Contract_Name',
        header: 'Contract Name',
        minSize: 200,
        maxSize: 200,
        size: 200,
        Cell: ({ renderedCellValue, row }) => (
          <div >
        <ExpandText text={row.original.Contract_Name} maxLength={40} />
 
          </div>
        ),
        muiTableHeadCellProps: { sx: { color: "#002677" }  }, //custom props
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder: 'Contract Name' },
      },
      {
        accessorKey: 'Payer_Name', //normal accessorKey
        header: 'Payer Name',
        minSize: 200,
        maxSize: 200,
        size: 200,
        muiTableHeadCellProps: { sx: { color: "#002677" }}, //custom props
        enableColumnFilter: true,
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder: 'Payer Name' },
      },
      {
        // accessorFn: (originalRow) =>
        //   {
        //   const val1 = originalRow["Effective_Date"];
        //   return val1 ? new Date(val1) : null;//transforming date first to check null
        // },
        accessorKey:"Effective_Date",
        header: 'Effective Date',
        filterVariant: 'date-range',
        filterFn: (row,columnId,filterValue)=>{
          const rowValue= new Date(row.getValue(columnId)); 
          const [start,end] = filterValue;
          const startDate = start?new Date(start):null;
          const endDate = end?new Date(end):null; 
          if(!start&&!end)
          return true; 
          if(startDate)
         startDate.setHours(0,0,0,0); 
          if(endDate)
          endDate.setHours(23,59,59,999);
          if(startDate&&!endDate)
          return rowValue>=startDate;
          if(!startDate&&endDate)
          return rowValue<=endDate;
          if(startDate&& endDate)
          return rowValue>=startDate && rowValue<=endDate;
        },
        size: 30,
        muiTableHeadCellProps: { sx: { color: "#002677" } }, //custom props
        // Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
        enableEditing:false,
        muiFilterTextFieldProps: { placeholder: 'Effective Date' },
      },
      {
        accessorKey: "Offshore_Prediction",
        header: 'Offshore Prediction',
        minSize: 30,
        maxSize: 30,
        size: 30,
        muiTableHeadCellProps: { sx: { color: "#002677" } }, //custom props
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder: 'Offshore Prediction' },
      },
      {
        accessorKey: 'Reviewed_By',
        header: 'Reviewed By',
        minSize: 30,
        maxSize: 30,
        size: 30,
        muiTableHeadCellProps: { sx: { color: "#002677" } }, //custom props
        enableEditing: false,
        muiFilterTextFieldProps: { placeholder: 'Reviewed By' },
        Cell:({row})=>{
          const offshoreFlag=row.original.Offshore_Prediction;
          return offshoreFlag==='Silent'?'Not Applicable':row.original.Reviewed_By},
      } ,
      {
        // accessorFn: (originalRow) =>
        //   {
        //   const val1 = originalRow["Review_Date"];
        //   return val1 ? new Date(val1) : null;//transforming date first to check null
        // },
        accessorKey:"Review_Date",
        header: 'Review Date',
        filterVariant: 'date-range',
        filterFn: (row,columnId,filterValue)=>{
          const rowValue= new Date(row.getValue(columnId)); 
          const [start,end] = filterValue;
          const startDate = start?new Date(start):null;
          const endDate = end?new Date(end):null; 
          if(!start&&!end)
          return true; 
          if(startDate)
         startDate.setHours(0,0,0,0); 
          if(endDate)
          endDate.setHours(23,59,59,999);
          if(startDate&&!endDate)
          return rowValue>=startDate;
          if(!startDate&&endDate)
          return rowValue<=endDate;
          if(startDate&& endDate)
          return rowValue>=startDate && rowValue<=endDate;
        },
        size: 30,
        muiTableHeadCellProps: { sx: { color: "#002677" } }, //custom props
        enableEditing:false,
        muiFilterTextFieldProps: { placeholder: 'Review Date' },
        Cell:({row})=>{
          const offshoreFlag=row.original.Offshore_Prediction;
          return offshoreFlag==='Silent'?'Not Applicable':row.original.Review_Date},
      
      },
      {
        accessorKey: 'Review_Status',
        header: 'Review Status',
        minSize: 100,
        maxSize: 100,
        size: 100,
        muiTableHeadCellProps: { sx: { color: "#002677" } }, //custom props
        enableEditing: true,
        muiFilterTextFieldProps: { placeholder: 'Review Status' },
        Cell:({cell,row})=>{
          const payer_name=cell.row.original.Payer_Name;
          const request_id=cell.row.original.Agreement_ID;
          const offshore_flag=cell.row.original.Offshore_Prediction;
          const review_status=cell.row.original.Review_Status;

          const handleClick=(e)=>{
            e.preventDefault();
            if(offshore_flag!=="Silent"){
            navigate(`/ClientSummary/${payer_name}?id=${request_id}`)
            }
          };
          const isLinkActive=offshore_flag!=='Silent';
          return isLinkActive?(
            <div>
            <a href='#' onClick={handleClick} style={{color:'blue',textDecoration:'underline'}}>
              {review_status}
            </a>
            </div>

          ):(<div>
            <span>{review_status}</span>
            {            offshore_flag==='Silent'?'Not Applicable':row.original.Review_Status}
            </div>

          )

        },
      },
    ],
    [reviewFlag,validationErrors],
  );
  
  const table = useMaterialReactTable({
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableGrouping: false,
    // initialState: { showColumnFilters: true },
    groupedColumnMode: 'remove',
    enableSorting: false,
        editDisplayMode: 'table',
    // muiTableContainerProps: { sx: { } },
    enableDensityToggle: false,
    enableFullScreenToggle:false,
    
    muiTableHeadCellProps: { sx: { backgroundColor: "transparent"  ,fontSize: {
      xs: '10px',
      sm: '11px',
      md: '11px',
      lg: '12px',
      xl: '13px',
    },
    padding: '5px',
    } }, //custom props
    initialState:{columnVisibility:{Agreement_ID:false}},
    muiTableHeadRowProps:{sx:{backgroundColor: "transparent"}},
    muiTopToolbarProps:{sx:{backgroundColor: "transparent"}},
    muiTableFooterRowProps:{sx:{backgroundColor: "transparent"}},
    muiBottomToolbarProps:{sx:{backgroundColor: "transparent"}},
     muiTablePaperProps:{
      sx:{
        boxShadow:'none',
        border:'none',
        backgroundColor:'transparent',
      }
    },
    muiTableProps:{
      sx:{
      borderCollapse:'collapse',
      }
    },
    // columnFilterDisplayMode: 'popover',
    muiTableBodyRowProps:{
      sx:(theme)=>( {
        borderSpacing:'0 10px',
        borderBottom:'5px solid transparent',
      }),
    },
    muiPaginationProps: {
      color: 'primary',
      shape: 'circular',
      showRowsPerPage: false,
      variant: 'outlined',
    },
    paginationDisplayMode: 'pages',
    renderTopToolbarCustomActions:({table})=>(
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          // padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownloadIcon />}
        >
          Export All Data
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() =>
            handleExportRows(table.getPrePaginationRowModel().rows)
          }
          startIcon={<FileDownloadIcon />}
        >
          Export Filtered Data
        </Button>
      </Box>
    ),
  });

  return (
    <div>
         {showTable &&
        <MaterialReactTable table={table} />
      }
    </div>
  )
}

export default LandingPageTable

