import React ,{useRef,forwardRef,useImperativeHandle}from 'react';
import {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';


const SnackBar = forwardRef(({defaultMessage="Some Error Occured",defaultSeverity="error"},ref) => {

    // useEffect(() => {
    //     setOpen(props.open);
    // }, [props.open])

    const [open, setOpen] = useState(false);
    const[alert,setAlerts]=useState({
        message:'',
        severity:'',
        open:false,
    })

    // const handleClick = () => {
    //     setOpen(true);
    // };

    const showSnackbar=(message=defaultMessage,severity=defaultSeverity)=>{
        setAlerts({
            message,severity,open:true,
        });
    };
    const CloseSnackbar=()=>{
        setAlerts((prev)=>({
            ...prev,open:false,
        }));
    };

    useImperativeHandle(ref,()=>({
        showSnackbar,
        CloseSnackbar,
        handleClose
    }));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setAlerts((prev)=>({
            ...prev,open:false,
        }));
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
    </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    //autoHideDuration={5000}

    return (
    <>
    <Snackbar open={alert.open}  anchorOrigin={{vertical:'top',horizontal:'center'}} action={action} onClose={handleClose}>
    <Alert severity={alert.severity} style={{ top:'60px'}} onClose={handleClose} sx={{whiteSpace:'pre-line',zIndex:'1301'}}>
        {alert.message}
       </Alert>
    </Snackbar>
    </>
    )
});

export default SnackBar;

