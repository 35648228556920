import React from 'react'
import Sidebar from '../Components/Sidebar';
import { Typography } from '@mui/material';



const Unauthorize = () => {
  return (
 
<div>
<div className="parent">
<div className="child1" >
</div>
<div className="main">
<div className="child">    <Sidebar/>
</div>
<div className="child3 content">
  <div className="width101" >
  <div style={{paddingTop:'15%'}}>
        <Typography variant="h6" gutterBottom>
            You are not authorized to access this application
           </Typography>
          </div>  

</div>

</div>  
</div>
</div>
</div>
  )
}

export default Unauthorize;