import React, { useState } from "react";
import Loader from "../Components/Loader";

const useLoader = (props) => {

  const [loading, setLoading] = useState(false);

  return [
    loading ? <Loader {...props} /> : null,
    () => setLoading(true),
    () => setLoading(false),
  ];
};

export default useLoader;