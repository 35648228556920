import { LogLevel } from "@azure/msal-browser";
import React from 'react';

export const ssoConfig = {
  auth: {
    clientId:process.env.REACT_APP_CLIENTID,
    authority:process.env.REACT_APP_AUTHORITY,
    redirectUri:"/",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            return;
          default:
            return;
        }
      },
    },
  },
};

export const ssoRequest = {
  scopes: ["User.Read"],
};

export const grrahApiRequest = {
  graphEndpoint: "https://graph.microsoft.com/v1.0/me",
};
