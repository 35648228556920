import React from 'react'
import { useState, useEffect, useContext } from 'react';
import Sidebar from '../Components/Sidebar';
import { Alert, Autocomplete, Box, Button, CircularProgress, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { ssoRequest } from "../config";
import useLoader from '../Hook/useLoader';
import ContractZoomLogo from '../Img/Contract_Zoom_Logo.png';
import axios from 'axios';
import { TokenGetter, GetUserInfo,GetUserInfoCode,getCookie,setCookie } from "../Endpoints/LoginActions";
import { useNavigate } from "react-router-dom";
import { UserContext} from '../Hook/UserContext';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


const Login = () => {

  const { instance, inProgress, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [keepSsoSilent, setKeepSsoSilent] = useState(true);
  const [isSsoSilentTrue, setIsSsoSilentTrue] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [loader, showLoader, hideLoader] = useLoader();

  const dispatch = useDispatch();



  //const { token, setToken, setEmailId }  = props;
  const [token,setToken] = useState();
  const [emailId,setEmailId] = useState();
  const [loginState,setLoginState] = useState();

  const {userRole,code,setCode} = useContext(UserContext);

  const userInfoSlice = useSelector((state) => {  //get data from the main store
    return state.userInfoSlice;
})


// useEffect(()=>{
//   console.log("userInfo saved is...",userInfo);
// },[userInfo])

  const navigate = useNavigate();

  useEffect(() => {
    showLoader();
  })

 

  if (inProgress === "none" && !isAuthenticated && keepSsoSilent) {
    instance.ssoSilent(ssoRequest).then(response => {

      console.log("ssoSilent response is", response);
      setIsSsoSilentTrue(true);
      if (inProgress === "none" && !isAuthenticated)
        instance.loginRedirect(ssoRequest);
      hideLoader();
    }).catch(error => {
      //console.log("reached catch");
      setKeepSsoSilent(false);
      setShowLoginForm(true);
      hideLoader();
    })


  }
  console.log("is Authenticated login is", isAuthenticated);
  const handleLogin = () => {
    console.log("handle login called");

    if (inProgress === "none" && !isAuthenticated)
      instance.loginRedirect(ssoRequest);
  }

  const handleOneHealthCareLogin = async () => {
   
    document.location.replace(`${process.env.REACT_APP_OHID_WRAPPER_AUTHCODE}?client_id=${process.env.REACT_APP_OHID_TOKEN_CLIENT_ID}&response_type=${process.env.REACT_APP_OHID_WRAPPER_AUTHCODE_RESPONSE_TYPE}&scope=${process.env.REACT_APP_OHID_WRAPPER_AUTHCODE_SCOPE}&redirect_uri=${process.env.REACT_APP_OHID_REDIRECT_URI}`);
    
  }

  

useEffect(()=>{
    let params = (new URL(document.location.href)).searchParams;
    const codeTemp = params.get("code");

    if(codeTemp){
      setCode(codeTemp);
    

      console.log("code is............",codeTemp);

      GetUserInfoCode(codeTemp,dispatch, (userInfo)=>{
        //setCookie("userInfo",userInfo,0 )
        //sessionStorage.setItem("userInfoObj",userInfo)
        

        

        //sessionStorage.setItem("userInfo",userInfo);
        console.log("userInfo in session storage saved........................")
        });
       
    }
},[]);




  return (
    <>

      <div className="LoginPage">


        <div style={{ position: 'fixed', left: '10%', top: '7%', width: '150%' }}>
          <img src={ContractZoomLogo} width='15%' />

        </div>


        <div className='UploadContent fixed' style={{ marginTop: '17%', marginLeft: '37%' }}  >
          {showLoginForm ?
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
              <div style={{ fontSize: '35px', color: 'black', textAlign: 'center', marginBottom: '8%', fontWeight: '400' }}>Sign In</div>
              <div style={{ fontSize: '16px', color: 'black', textAlign: 'center', marginBottom: '6%' }}>Please Select a Sign In Method</div>

              <div className="row" style={{width:"120%",marginLeft:'-10%'}}>
                <div className="col-md-6 col-12 ">
                <Button
                variant="contained"
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={handleLogin}
                sx={{
                  backgroundColor: '#002677',
                  width: '100%',
                  height: '80px',
                  fontSize: '16px',
                  color: 'white',
                  textOverflow: 'wrap',
                }}
              >

                Using my <br /> Email Address
            </Button>
                </div>
                <div className="col-md-6 col-12 ">
                <Button
                variant="contained"
                //export all rows, including from the next page, (still respects filtering and sorting)
                onClick={handleOneHealthCareLogin}
                sx={{
                  backgroundColor: '#002677',
                  width: '100%',
                  height: '80px',
                  fontSize: '16px',
                  color: 'white',
                  textOverflow: 'wrap',
                }}
              >

                Using my <br /> One HealthCare ID
            </Button> </div> 
                </div>
              </div>


              
             : (<div>
              {loader}
            </div>)
          }</div>
       
      </div>

    </>
  )
}

export default Login;